import { memo as Memo, useMemo } from 'react'

//* HOC's
import { withUIContext, withUserContext } from 'context'

//* Components
import Container from 'components/common/Container'
import Text from 'components/common/Text'
import Image from 'components/common/Image'
import CustomLink from 'components/common/CustomLink'

//* Helpers
import { config } from 'helpers'

//* Styles
import ClubsIconSectionStyle from './style'

const ClubsIconSection = Memo(({ clubs, user }) => {
    //! Clubs HTML
    const clubsHTML = useMemo(() => {
        return clubs.map((cl, k) => (
            <div
                className='cls-col col-2 col-ts-4 col-m-6'
                key={k}
            >
                <CustomLink
                    url={`${user ? `${config.routes.clubs.path}/${cl.slug}` : config.routes.signIn.path}`}
                    className='cls-club sweet-ariel-bg'
                >
                    <Image
                        className='cls-image'
                        src={cl?.logo?.src}
                        alt={cl?.logo?.alt}
                    />
                </CustomLink>
            </div>
        ))
    }, [clubs, user])

    return (
        <ClubsIconSectionStyle>
            <Container section>
                <Text
                    tag='h2'
                    text={'someOfOurClubs'}
                    className='cls-title h3 font-reckless-neue-semibold birodo-green'
                />

                <Container
                    className='cls-clubs-cont'
                    row
                    full
                >
                    {clubsHTML}
                </Container>
            </Container>
        </ClubsIconSectionStyle>
    )
})

export default withUserContext(ClubsIconSection, ['user'])
