import styled from 'styled-components'

const FAQSectionStyle = styled.section`
	--smallWidth: 40.63%;
	--normalWidth: 50%;
	--largeWidth: calc(100% - var(--smallWidth));
	--faqBtnMLeft: var(--sp7x);

	.faq-section-title {
		text-align: center;
	}

	.faq-items-wrapper {
		margin-top: var(--titlePictureBackgroundPadding);
		display: flex;
		flex-wrap: wrap;

		.faq-item-wrap {
			&.small {
				width: var(--smallWidth);
			}

			&.normal {
				width: var(--normalWidth);
			}

			&.large {
				width: var(--largeWidth);
			}
		}
	}

	.faq-title-btn-wrapper {
		display: flex;
		align-items: center;

		.faq-view-all-btn {
			margin-left: var(--faqBtnMLeft);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--smallWidth: 40.57%;
		--faqBtnMLeft: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--smallWidth: 40.79%;
		--faqBtnMLeft: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--smallWidth: 40.55%;
		--faqBtnMLeft: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--smallWidth: 40.46%;
		--faqBtnMLeft: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--smallWidth: 40.21%;
		--faqBtnMLeft: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--smallWidth: 50%;
		--normalWidth: 50%;
		--largeWidth: 50%;
		--faqBtnMLeft: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--smallWidth: 100%;
		--normalWidth: 100%;
		--largeWidth: 100%;
		--faqBtnMLeft: var(--sp3x);

		.col-m-0 {
			width: 0 !important;
		}
	}
`

export default FAQSectionStyle
