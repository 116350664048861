import { memo as Memo, useCallback, useMemo } from 'react'

//* Components
import Container from 'components/common/Container'
import Text from 'components/common/Text'
import Button from 'components/common/Button'

//* Styles
import OffersSectionStyle from './style'
import { config } from 'helpers'

const OffersSection = Memo(({ title, offers, entries }) => {
    //! Create Offer
    const createOffer = useCallback(
        ({ title, subtitle, text, entries, k }) => {
            const isSecond = k === 1

            const buttonProps = isSecond
                ? {
                    color: 'birodoGreen',
                    backgroundColor: 'pureWhite',
                    borderColor: 'birodoGreen',
                }
                : {
                    color: 'white',
                    backgroundColor: 'birodoGreen',
                    borderColor: 'birodoGreen',
                }

            const textFontFamily = isSecond ? 'font-jakarta-sans-medium' : 'font-jakarta-sans-regular'
            return (
                <div
                    key={k}
                    className={`os-offer-cont ${entries ? 'os-offer-cont-with-entries' : ''} ${isSecond ? 'os-second' : ''}`}
                >
                    <div className='os-offer-info-cont'>
                        <div className={`os-left ${isSecond ? 'pure-white-bg' : 'birodo-green-bg'}`}>
                            <Text
                                text={title}
                                className='os-offer-title h4 font-jakarta-sans-semibold'
                            />

                            <Text
                                text={subtitle}
                                className={`os-offer-subtitle p p2 ${textFontFamily}`}
                            />
                        </div>

                        <div className={`os-right ${isSecond ? 'pure-white-bg' : 'birodo-green-bg'}`}>
                            <Text
                                text={text}
                                className={`os-offer-text  p p2 ${textFontFamily}`}
                            />

                            {entries &&
                                entries.map((entry, key) => (
                                    <div
                                        key={`${k}-${key}`}
                                        className='os-entry-cont'
                                    >
                                        <Text
                                            text={entry.title}
                                            className='os-entry-title h6 font-jakarta-sans-semibold'
                                        />
                                        <Text
                                            text={entry.text}
                                            className={`os-entry-text p p2 ${textFontFamily}`}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>

                    <Button
                        size={'big'}
                        isVertical
                        text={isSecond ? 'apply' : 'joinUs'}
                        url={isSecond ? config.routes.clubApplication.path : config.routes.signUp.path}
                        className='os-offer-button'
                        {...buttonProps}
                    />
                </div>
            )
        },
        [config]
    )

    const offersHTML = useMemo(() => {
        return <div className='os-offers-cont'>{offers.map((o, k) => createOffer({ ...o, k }))}</div>
    }, [offers])

    return (
        <OffersSectionStyle>
            <Container
                section
                first
            >
                {title && (
                    <Text
                        tag='h2'
                        text={title}
                        className='os-title h3 font-reckless-neue-semibold birodo-green'
                    />
                )}

                {offersHTML}
            </Container>
        </OffersSectionStyle>
    )
})

export default OffersSection
