import styled from 'styled-components'

const OffersSectionStyle = styled.div`
    --osTitleMarginBottom: var(--sp12x);
    --osOfferMarginBottom: var(--sp1x);
    --osOfferBorderRadius: var(--sp4x);
    --osOfferPadding: var(--sp13x);
    --osOfferBorderWidth: var(--sp0-5x);
    --osOfferRightPaddingLeft: var(--osOfferPadding);
    --osOfferLeftMaxWidth: 35.546vw;
    --osOfferTitleMarginBottom: var(--sp5x);
    --osOfferBtnMargin: 0 0 0 var(--sp1x);
    --osOfferTextMarginBottom: var(--sp25x);
    --osEntryMarginTop: var(--sp12x);
    --osEntryTitleMarginBottom: var(--sp5x);
    --osOfferWithEntriesMarginBottom: var(--sp19x);
    --osOfferTextWithEntriesMarginBottom: var(--sp12x);

    .os-title {
        margin-bottom: var(--osTitleMarginBottom);
    }

    .os-offers-cont {
        display: flex;
        flex-direction: column;

        .os-offer-cont {
            --borderColor: var(--birodoGreen);

            display: flex;

            &:not(:last-child) {
                margin-bottom: var(--osOfferMarginBottom);
            }

            .os-offer-info-cont {
                display: flex;

                .os-left,
                .os-right {
                    border: var(--osOfferBorderWidth) solid var(--borderColor);
                    padding: var(--osOfferPadding);
                    border-radius: var(--osOfferBorderRadius);

                    > * {
                        position: relative;
                        z-index: 1;
                    }
                }

                .os-left {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 100%;
                    min-width: var(--osOfferLeftMaxWidth);
                    max-width: var(--osOfferLeftMaxWidth);

                    .os-offer-title {
                        margin-bottom: var(--osOfferTitleMarginBottom);
                    }
                }

                .os-right {
                    padding-left: var(--osOfferRightPaddingLeft);

                    .os-offer-text {
                        white-space: break-spaces;
                        margin-bottom: var(--osOfferTextMarginBottom);
                    }

                    .os-entry-cont {
                        &:not(:first-child) {
                            margin-top: var(--osEntryMarginTop);
                        }

                        .os-entry-title {
                            margin-bottom: var(--osEntryTitleMarginBottom);
                        }

                        .os-entry-text {
                            white-space: break-spaces;
                        }
                    }
                }
            }

            .os-offer-button {
                margin: var(--osOfferBtnMargin);
            }

            &.os-offer-cont-with-entries {
                --osOfferMarginBottom: var(--osOfferWithEntriesMarginBottom);
                --osOfferTextMarginBottom: var(--osOfferTextWithEntriesMarginBottom);
            }

            &.os-second {
                .os-offer-info-cont {
                    position: relative;

                    .os-left {
                        &:before {
                            content: "";
                            position: absolute;
                            top: calc(var(--osOfferBorderRadius) * 0.8);
                            left: var(--osOfferBorderWidth);
                            right: var(--osOfferBorderWidth);
                            bottom: calc(var(--osOfferBorderRadius) * 0.8);
                            background: var(--pureWhite);
                        }
                    }
                }
            }
        }
    }
    
    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
        --osTitleMarginBottom: var(--sp9x);
        --osOfferMarginBottom: var(--sp1x);
        --osOfferBorderRadius: var(--sp4x);
        --osOfferPadding: var(--sp10x);
        --osOfferBorderWidth: var(--sp0-5x);
        --osOfferRightPaddingLeft: var(--osOfferPadding);
        --osOfferLeftMaxWidth: 35.833vw;
        --osOfferBtnMargin: 0 0 0 var(--sp1x);
        --osOfferTextMarginBottom: var(--sp12x);
        --osEntryMarginTop: var(--sp9x);
        --osEntryTitleMarginBottom: var(--sp4x);
        --osOfferWithEntriesMarginBottom: var(--sp15x);
        --osOfferTextWithEntriesMarginBottom: var(--sp10x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
        --osTitleMarginBottom: var(--sp7x);
        --osOfferMarginBottom: var(--sp1x);
        --osOfferBorderRadius: var(--sp2-5x);
        --osOfferPadding: var(--sp8x);
        --osOfferBorderWidth: var(--sp0-5x);
        --osOfferRightPaddingLeft: var(--osOfferPadding);
        --osOfferLeftMaxWidth: 35.165vw;
        --osOfferBtnMargin: 0 0 0 var(--sp1x);
        --osOfferTextMarginBottom: var(--sp10x);
        --osEntryMarginTop: var(--sp7x);
        --osEntryTitleMarginBottom: var(--sp3x);
        --osOfferWithEntriesMarginBottom: var(--sp11x);
        --osOfferTextWithEntriesMarginBottom: var(--sp8x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
        --osTitleMarginBottom: var(--sp7x);
        --osOfferMarginBottom: var(--sp1x);
        --osOfferBorderRadius: var(--sp2-5x);
        --osOfferPadding: var(--sp8x);
        --osOfferBorderWidth: var(--sp0-5x);
        --osOfferRightPaddingLeft: var(--osOfferPadding);
        --osOfferLeftMaxWidth: 35.138vw;
        --osOfferBtnMargin: 0 0 0 var(--sp1x);
        --osOfferTextMarginBottom: var(--sp10x);
        --osEntryMarginTop: var(--sp7x);
        --osEntryTitleMarginBottom: var(--sp3x);
        --osOfferWithEntriesMarginBottom: var(--sp11x);
        --osOfferTextWithEntriesMarginBottom: var(--sp8x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
        --osTitleMarginBottom: var(--sp6x);
        --osOfferMarginBottom: var(--sp1x);
        --osOfferBorderRadius: var(--sp2-5x);
        --osOfferPadding: var(--sp7x);
        --osOfferBorderWidth: var(--sp0-5x);
        --osOfferRightPaddingLeft: var(--osOfferPadding);
        --osOfferLeftMaxWidth: 35.234vw;
        --osOfferBtnMargin: 0 0 0 var(--sp1x);
        --osOfferTextMarginBottom: var(--sp10x);
        --osEntryMarginTop: var(--sp6x);
        --osEntryTitleMarginBottom: var(--sp3x);
        --osOfferWithEntriesMarginBottom: var(--sp9x);
        --osOfferTextWithEntriesMarginBottom: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
        --osTitleMarginBottom: var(--sp5x);
        --osOfferMarginBottom: var(--sp1x);
        --osOfferBorderRadius: var(--sp2x);
        --osOfferPadding: var(--sp6x);
        --osOfferBorderWidth: 3px;
        --osOfferRightPaddingLeft: var(--osOfferPadding);
        --osOfferLeftMaxWidth: 29.687vw;
        --osOfferBtnMargin: 0 0 0 var(--sp1x);
        --osOfferTextMarginBottom: var(--sp8x);
        --osEntryMarginTop: var(--sp4x);
        --osEntryTitleMarginBottom: var(--sp2x);
        --osOfferWithEntriesMarginBottom: var(--sp7x);
        --osOfferTextWithEntriesMarginBottom: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
        .os-offers-cont .os-offer-cont {
            flex-wrap: wrap;

            .os-offer-info-cont {
                flex-wrap: wrap;
            }

            &.os-second .os-offer-info-cont .os-left:before {
                top: var(--osOfferBorderWidth);
                left: calc(var(--osOfferBorderRadius) * 0.8);
                right: calc(var(--osOfferBorderRadius) * 0.8);
                bottom: var(--osOfferBorderWidth);
            }
        }
    }

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
        --osTitleMarginBottom: var(--sp5x);
        --osOfferMarginBottom: var(--sp3x);
        --osOfferBorderRadius: var(--sp2x);
        --osOfferPadding: var(--sp5x);
        --osOfferBorderWidth: 3px;
        --osOfferRightPaddingLeft: var(--sp24x);
        --osOfferLeftMaxWidth: 100%;
        --osOfferBtnMargin: var(--sp0-5x) 0 0 0;
        --osOfferTextMarginBottom: 0px;
        --osEntryMarginTop: var(--sp4x);
        --osEntryTitleMarginBottom: var(--sp2x);
        --osOfferWithEntriesMarginBottom: var(--sp7x);
        --osOfferTextWithEntriesMarginBottom: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
        --osTitleMarginBottom: var(--sp5x);
        --osOfferMarginBottom: var(--sp4x);
        --osOfferBorderRadius: var(--sp1-5x);
        --osOfferPadding: var(--sp5x);
        --osOfferBorderWidth: 3px;
        --osOfferRightPaddingLeft: var(--sp12x);
        --osOfferLeftMaxWidth: 100%;
        --osOfferBtnMargin: var(--sp0-5x) 0 0 0;
        --osOfferTextMarginBottom: 0px;
        --osEntryMarginTop: var(--sp4x);
        --osEntryTitleMarginBottom: var(--sp2x);
        --osOfferWithEntriesMarginBottom: var(--sp5x);
        --osOfferTextWithEntriesMarginBottom: var(--sp5x);
	}
`

export default OffersSectionStyle