import { memo as Memo } from 'react'

//* Style
import EditorStyle from './style'

const Editor = Memo(({ className, data }) => {
	return (
		<EditorStyle
			dangerouslySetInnerHTML={{ __html: data }}
			className={`editor-wrapper ${className || ''}`}
		/>
	)
})

export default Editor
