import { memo as Memo } from 'react'

//* Helper
import { config } from 'helpers'

//* Components
import Text from 'components/common/Text'
import Button from 'components/common/Button'
import Container from 'components/common/Container'
import Item from './Item'

//* Style
import FAQSectionStyle from './style'

const FAQSection = Memo(({ className, title, data, section, first, isFAQ = false }) => {
    return (
        <FAQSectionStyle className={`faq-section ${className || ''}`}>
            <Container
                first={first}
                section={section}
            >
                {isFAQ ? (
                    <Container
                        row
                        full
                    >
                        <div className={`empty col-2 col-dl-3 col-dm-2 col-m-0`} />

                        <div className={`col-8 col-dl-6 col-dm-8 col-m-12`}>
                            <Text
                                tag={`h3`}
                                className={`h3 font-reckless-neue-semibold birodo-green faq-section-title`}
                                text={title}
                            />
                        </div>

                        <div className={`empty col-2 col-dl-3 col-dm-2 col-m-0`} />
                    </Container>
                ) : (
                    <div className={`faq-title-btn-wrapper`}>
                        <Text
                            tag={`h3`}
                            className={`h3 font-reckless-neue-semibold birodo-green`}
                            text={`faq`}
                        />

                        <Button
                            text={`viewAll`}
                            btnType={`link`}
                            color={`birodoGreen`}
                            url={config.routes.faq.path}
                            className={`faq-view-all-btn`}
                        />
                    </div>
                )}

                <div className={`faq-items-wrapper`}>
                    {data.map((item, index) => (
                        <div
                            key={index}
                            className={`faq-item-wrap ${index % 6 === 0 || index % 6 === 5 ? 'small' : index % 6 === 1 || index % 6 === 4 ? 'large' : index % 6 === 2 || index % 6 === 3 ? 'normal' : ''
                                }`}
                        >
                            <Item
                                title={item.title}
                                data={item.text}
                            />
                        </div>
                    ))}
                </div>
            </Container>
        </FAQSectionStyle>
    )
})

export default FAQSection
