import styled from 'styled-components'

const EditorStyle = styled.div`
	--largeDistance: var(--sp4x);
	--smallDistance: var(--sp3x);
	--paddingInlineStart: 1.7em;

	margin-top: var(--largeDistance);

	h5 {
		font-size: var(--h6);
		font-family: var(--plusJakartaSans);
		font-weight: 600 !important;
		color: var(--birodoGreen);
		line-height: var(--lineHeightH6FontJakarta);

		&:not(:first-child) {
			margin-top: var(--largeDistance);
		}
	}

	h6 {
		font-size: var(--p1);
		font-family: var(--plusJakartaSans);
		font-weight: 600 !important;
		color: var(--birodoGreen);
		line-height: var(--lineHeightP1FontJakarta);

		&:not(:first-child) {
			margin-top: var(--largeDistance);
		}
	}

	p {
		font-size: var(--p2);
		font-family: var(--plusJakartaSans);
		font-weight: 500 !important;
		color: var(--birodoGreen);
		line-height: var(--lineHeightP2FontJakarta);

		&:not(:first-child) {
			margin-top: var(--smallDistance);
		}
	}

	ul {
		margin-top: var(--largeDistance);
		list-style-type: disc;
		padding-inline-start: var(--paddingInlineStart);

		li {
			font-size: var(--p2);
			font-family: var(--plusJakartaSans);
			font-weight: 500 !important;
			line-height: var(--lineHeightP2FontJakarta);
			color: var(--birodoGreen);
		}

		ul {
			margin-top: 0;
		}
	}

	i {
		font-size: var(--p2);
		line-height: var(--lineHeightP2FontJakarta);
		color: var(--birodoGreen);
	}

	strong {
		font-size: var(--p2);
		line-height: var(--lineHeightP2FontJakarta);
		color: var(--birodoGreen);
		font-family: var(--plusJakartaSans);
		font-weight: 700 !important;
	}

	ol {
		margin-block-start: 0;
		margin-block-end: 0;
		padding-inline-start: var(--paddingInlineStart);
		margin-top: var(--largeDistance);

		li {
			font-size: var(--p2);
			font-family: var(--plusJakartaSans);
			font-weight: 500 !important;
			line-height: var(--lineHeightP2FontJakarta);
			color: var(--birodoGreen);
		}

		ol {
			margin-top: 0;
		}
	}

	blockquote {
		border-left: 5px solid var(--birodoGreen);
		font-size: var(--p2);
		line-height: var(--lineHeightP2FontJakarta);
		font-family: var(--plusJakartaSans);
		font-weight: 500 !important;
		color: var(--birodoGreen);
		margin-block-start: 0;
		margin-block-end: 0;
		margin-inline-start: 0;
		margin-inline-end: 0;
		margin-top: var(--largeDistance);

		p {
			margin-left: var(--smallDistance);
		}
	}

	a {
		font-family: var(--plusJakartaSans);
		font-weight: 500 !important;
		font-size: var(--p2);
		line-height: var(--lineHeightP2FontJakarta);
		text-decoration: underline;
		color: var(--birodoGreen);
		transition: all var(--trTime) ease-out;

		@media (hover: hover) {
			&:hover {
				text-decoration: none;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--largeDistance: var(--sp3x);
		--smallDistance: var(--sp2x);
		--paddingInlineStart: 1.5em;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--largeDistance: var(--sp3x);
		--smallDistance: var(--sp2x);
		--paddingInlineStart: 1.3em;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--largeDistance: var(--sp3x);
		--smallDistance: var(--sp2x);
		--paddingInlineStart: 1.3em;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--largeDistance: var(--sp2x);
		--smallDistance: var(--sp1-5x);
		--paddingInlineStart: 1.2em;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--largeDistance: var(--sp2x);
		--smallDistance: var(--sp1-5x);
		--paddingInlineStart: 1.2em;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--largeDistance: var(--sp2x);
		--smallDistance: var(--sp1-5x);
		--paddingInlineStart: 1.2em;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--largeDistance: var(--sp2x);
		--smallDistance: var(--sp1-5x);
		--paddingInlineStart: 1.1em;
	}
`

export default EditorStyle
