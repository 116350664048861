import styled from 'styled-components'

const ClubsIconSectionStyle = styled.div`
    --clsTitleMarginBottom: var(--sp12x);
    --clsClubPadding: var(--sp4x);
    --clsClubBorderRadius: var(--sp1x);

    .cls-title {
        margin-bottom: var(--clsTitleMarginBottom);
    }

    .cls-clubs-cont {
        > .row {
            margin-bottom: calc(-1 * var(--colPadding));

            .cls-col {
                padding-bottom: var(--colPadding);

                .cls-club {
                    display: flex;
                    align-items: center;
                    padding: var(--clsClubPadding);
                    border-radius: var(--clsClubBorderRadius);

                    .cls-image {
                        --proportion: 61.23%;

                        img {
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
        --clsTitleMarginBottom: var(--sp9x);
        --clsClubPadding: var(--sp3x);
        --clsClubBorderRadius: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
        --clsTitleMarginBottom: var(--sp7x);
        --clsClubPadding: var(--sp3x);
        --clsClubBorderRadius: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
        --clsTitleMarginBottom: var(--sp7x);
        --clsClubPadding: var(--sp3x);
        --clsClubBorderRadius: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
        --clsTitleMarginBottom: var(--sp6x);
        --clsClubPadding: var(--sp2x);
        --clsClubBorderRadius: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
        --clsTitleMarginBottom: var(--sp5x);
        --clsClubPadding: var(--sp2x);
        --clsClubBorderRadius: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
        --clsTitleMarginBottom: var(--sp5x);
        --clsClubPadding: var(--sp2x);
        --clsClubBorderRadius: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
        --clsTitleMarginBottom: var(--sp4x);
        --clsClubPadding: var(--sp2x);
        --clsClubBorderRadius: var(--sp1x);
	}
`

export default ClubsIconSectionStyle