import { memo as Memo } from 'react'

//* Components
import Text from 'components/common/Text'
import Editor from 'components/common/Editor'

//* Style
import ItemStyle from './style'

const Item = Memo(({ className, title, data }) => {
    return (
        <ItemStyle className={`faq-item ${className || ''} cryo-freeze-bg`}>
            <Text
                className={`h6 font-jakarta-sans-semibold birodo-green`}
                text={title}
            />

            <Editor data={data} />
        </ItemStyle>
    )
})

export default Item
